import React from 'react'
import { Link } from 'gatsby'
import Fuse from 'fuse.js'

import '../../styles/components/search.scss'

class Search extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      allPosts: [],
      value: "",
      results: [],
      fuseOptions: {
        shouldSort: true,
        threshold: 0.5,
        location: 0,
        distance: 200,
        maxPatternLength: 33,
        minMatchCharLength: 3,
        keys: ['title', 'html']
      },
      open: false,
      disable: true,
      loading: true,
      error: false
    }
    this.timer
		this.searchInput
  }

	requestApi = () => {
		const
      url = "https://ghost.thinkplus.dev/ghost/api",
      key = "08c6fd98e8591955451902f003",
      version = "v2",
			resource = "posts",
			limit = "all",
			include = "tags",
			fields = "id,title,html,slug";

		return fetch(
			encodeURI(
				`${url}/${version}/content/${resource}/?key=${key}&limit=${limit}&include=${include}&fields=${fields}`
			), {
				method: "get",
				headers: {
					"Accept-Version": "v2"
				},
			})
			.then((res) => res.json())
			.then((res) => {
				if (!Array.isArray(res[resource])) {
					return res[resource];
				}
				if (res[resource].length === 1 && !res.meta) {
					return res[resource][0];
				}
				return Object.assign(res[resource], {meta: res.meta});
			})
			.catch((err) => {
				if (err.response && err.response && err.response.errors) {
					const props = err.response.errors[0];
					const toThrow = new Error(props.message);
					const keys = Object.keys(props);

					toThrow.name = props.type;

					keys.forEach((k) => {
							toThrow[k] = props[k];
					});

					toThrow.response = err.response;

					toThrow.request = err.request;
					toThrow.config = err.config;

					throw toThrow;
				} else {
					throw err;
				}
			});
	}

  getAllPosts = () => {
    if (sessionStorage.getItem('allPosts')) {
      if (this.state.results.length < 1)
			this.setState(
				{ allPosts: JSON.parse(sessionStorage.getItem('allPosts')) }
			);

      this.setState({ disable: false, loading: false })
      setTimeout(()=>{this.searchInput.focus()}, 10)
    } else {
			this.requestApi()
			.then((res) => {
        sessionStorage.setItem('allPosts', JSON.stringify(res))
        this.setState({ allPosts: res, disable: false })
        this.searchInput.focus()
      }).catch(res => {
        this.setState({ disable: true, error: true })
        console.log(res)
      }).finally(() => {
        this.setState({ loading: false })
      })
    }
  }

  removeQueryParam = () => {
    history.replaceState(null, '', `${window.location.pathname}`)
  }

  addQueryParam = () => {
    history.replaceState(null, '', `${window.location.pathname}?q=${this.state.value}`)
  }

  handleSearch = (e) => {
    this.setState({ value: e.target.value })
    clearTimeout(this.timer)
    this.timer = setTimeout(()=>{
      this.addQueryParam()
      this.state.value == '' && this.removeQueryParam()
      window.dataLayer && dataLayer.push({
        'event': 'query',
        'query_string': this.state.value
      })
    }, 1000)
    let fuse = new Fuse(this.state.allPosts, this.state.fuseOptions)
    this.setState({ results: fuse.search(e.target.value) })
  }

  open = () => {
    this.getAllPosts()
    document.getElementsByClassName('search__content')[0].hidden = false
    setTimeout(()=>{this.setState({ open: true })}, 10)
  }

  close = () => {
    this.setState({ open: false })
    this.removeQueryParam()
    setTimeout(()=>{document.getElementsByClassName('search__content')[0].hidden = true}, 210)
  }

  componentDidMount() {
    window.addEventListener('load', this.removeQueryParam)
  }

  render() {
    let tagName = 'Our Work',
        tagSlug = 'our-work'

    return (
      <>
        <button className="search__icon" aria-label="Search" onClick={this.open}>
					<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M15.853 16.56c-1.683 1.517-3.911 2.44-6.353 2.44-5.243 0-9.5-4.257-9.5-9.5s4.257-9.5 9.5-9.5 9.5 4.257 9.5 9.5c0 2.442-.923 4.67-2.44 6.353l7.44 7.44-.707.707-7.44-7.44zm-6.353-15.56c4.691 0 8.5 3.809 8.5 8.5s-3.809 8.5-8.5 8.5-8.5-3.809-8.5-8.5 3.809-8.5 8.5-8.5z"/></svg>
        </button>
        <section className={`search__content${this.state.open?" is-opened":""}`} aria-hidden={!this.state.open} hidden={true}>
          <button className="search__close" aria-haspopup="true" aria-label="Close search" onClick={this.close}>
						<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z"/></svg>
					</button>
          <div className="container medium">
            <div className="search__grid">
              <div className="search__header h3">
								<svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M15.853 16.56c-1.683 1.517-3.911 2.44-6.353 2.44-5.243 0-9.5-4.257-9.5-9.5s4.257-9.5 9.5-9.5 9.5 4.257 9.5 9.5c0 2.442-.923 4.67-2.44 6.353l7.44 7.44-.707.707-7.44-7.44zm-6.353-15.56c4.691 0 8.5 3.809 8.5 8.5s-3.809 8.5-8.5 8.5-8.5-3.809-8.5-8.5 3.809-8.5 8.5-8.5z"/></svg>
                <label htmlFor="search_input" className="visually-hidden">Search</label>
                <input type="search" autoComplete="off" className="search__input" id="search_input" ref={input => { this.searchInput = input }} value={this.state.value} onChange={this.handleSearch} placeholder="Search... go for it!" disabled={this.state.disable} />
                <span className={`loading${this.state.loading?" show":""}`}></span>
              </div>
              <div className="search__results">
                {this.state.error && <p className="search__error h6">You did nothing wrong, but there was a problem with search. Please try again later!</p>}
                {(this.state.results.length == 0 && this.state.value != "") && <div className="search__no-results text-align-center" id="search_no_results">
                  <p className="h5">No results found.</p>
                  {this.state.value.length < this.state.fuseOptions.minMatchCharLength && <p className="search__no-results-message">Keep typing, only {this.state.fuseOptions.minMatchCharLength - this.state.value.length} {this.state.fuseOptions.minMatchCharLength - this.state.value.length > 1 ? 'letters' : 'letter'} left for your results...</p>}
                  {this.state.value.length >= this.state.fuseOptions.minMatchCharLength && <p className="search__no-results-message">We don't have anything about <b>"{this.state.value}"</b> yet, <br/>but you can <Link to='/contact-us' onClick={this.removeQueryParam}>contact us</Link> and find what you're looking for.</p>}
                  <div className="horizontal-list">
                    <p className="h6">Or you could browse: </p>
                    <Link to='/our-work' onClick={this.removeQueryParam} className="search__result--tag our-work h6">Our Work</Link>
                    <Link to='/blog' onClick={this.removeQueryParam} className="search__result--tag blog h6">Blog</Link>
                    <Link to='/news' onClick={this.removeQueryParam} className="search__result--tag news h6">News</Link>
                  </div>
                </div>}
                {this.state.results.map((result, index) => {
                  result.item.tags && result.item.tags.some(tag=>{
                    if (tag.name === 'News') {
                      tagName = 'News'
                      tagSlug = 'news'
                    } else if (tag.name === 'blog') {
                      tagName = 'Blog'
                      tagSlug = 'blog'
                    } else if (tag.name === '#portfolio') {
                      tagName = 'Our Work'
                      tagSlug = 'our-work'
                    }
                  })
                  return <p key={result.item.id} className="search__result h5" style={{'--index': index+1}}>
                    <Link to={`/${tagSlug}`} onClick={this.removeQueryParam} className={`search__result--tag ${tagSlug}`}>{tagName}</Link>
                    <Link to={`/${result.item.slug}`} onClick={this.removeQueryParam} className="search__result--title">{result.item.title}</Link>
                  </p>
                })}
              </div>
            </div>
          </div>
        </section>
      </>
    )
  }
}

export default Search
